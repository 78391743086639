import { PayloadAction } from '@reduxjs/toolkit';

import {
	FileData,
	FileUploadState,
	createFileUploadSlice,
	initialFileState,
} from 'library/redux/fileUpload';

import { resetGuidedRegistrationState } from './Thunks';

export interface guidedRegistrationFileUploadState extends FileUploadState {
	profilePicturePath: FileData | null;
}

const guidedRegistrationFileInitialState: guidedRegistrationFileUploadState = {
	...initialFileState,
	profilePicturePath: null,
};

const guidedRegistrationFilesSlice = createFileUploadSlice({
	name: 'guidedRegistrationFiles',
	initialState: guidedRegistrationFileInitialState,
	reducers: {
		newProfilePicture: (state, { payload }: PayloadAction<FileData>) => {
			// todo - when to delete item
			state.profilePicturePath = payload;
		},
	},
	extraReducers(builder) {
		builder.addCase(
			resetGuidedRegistrationState.fulfilled,
			() => guidedRegistrationFileInitialState
		);
	},
});

export const {
	fileUploaded,
	fileDeleted,
	attachmentFolderCreated,
	newProfilePicture,
} = guidedRegistrationFilesSlice.actions;

export default guidedRegistrationFilesSlice.reducer;
