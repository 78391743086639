import { EntityState } from '@reduxjs/toolkit';

import { DrivelineViewDto, ServiceProtocolDto } from 'api';
import { Unit } from 'api/responses/models/Unit/UnitStructure/Unit';
import { InstallationDto } from 'api/responses/models/UnitCreation/ProductIndividualSearchResultDto';
import { ProductIndividualSearchResult } from 'domain/productIndividual';
import { InstallationDrivelineId } from 'domain/unit';
import { PropWithTestId, Removable } from 'library/types';

export type FileData = {
	readonly name: string;
	readonly serverName: string;
	readonly size: number;
	readonly type: string;
	readonly lastModified: number;
	readonly fileUrl: string;
};

export enum ApiStatus {
	Idle = 0,
	Pending = 1,
	Fulfilled = 2,
	Rejected = 3,
}

export enum UnitConfigurationType {
	CREATION = 'CREATION',
	EDIT = 'EDIT',
}

export interface CommonUnitState {
	status: ApiStatus;
}

export type InstallationStateList = EntityState<Installation, string>;

export interface UnitState extends CommonUnitState {
	activeStep: number;
	installationList: InstallationStateList;
	isBlueprint: boolean;
	searchItemsList: EntityState<ProductIndividualSearchResult, string>;
	searchItemWithConfigurationDetected: ProductIndividualSearchResult | null;
	shouldLoadConfigurationForItem: boolean | null;
	type: UnitConfigurationType;
	unitMetadata: UnitMetadata;
}

export interface Installation extends InstallationDto {
	id?: number;
	generatedUuid: string;
}

export type InstallationMetadata = Omit<InstallationDto, 'drivelines'>;

export type MainSegment = 'M' | 'I';

export type ProductIndividualSearchNoResults = {
	errorMessage: string;
	serialNumber: string | null;
};

export type UnitMetadata = {
	lineOfBusiness?: string;
	lineOfBusinessDescription?: string;
	unitPurpose?: string;
	unitPurposeDescription?: string;
	mainSegment: MainSegment | null;
};

export interface DrivelineHeaderAction
	extends Removable,
		InstallationDrivelineId,
		PropWithTestId {}

export interface UnitProps {
	unit: Unit;
}

export interface DrivelineProps {
	driveline: DrivelineViewDto;
}

export interface ServiceProtocolProps {
	serviceProtocol: ServiceProtocolDto;
}
