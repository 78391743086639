import { size } from 'lodash';
import { useTranslation } from 'react-i18next';

import { ProductIndividualType } from 'domain/unit/enums';
import { ParseKeys } from 'i18next';
import {
	AccordionContainer,
	AccordionItem,
	ActionDialogChildProps,
} from 'library/components/controls';

import { LongblockReplaceForm } from './LongblockReplaceForm';
import { RepowerReplaceFrom } from './RepowerReplaceFrom';
import { SwapReplaceForm } from './SwapGeneratorReplaceForm';
import { IndividualReplaceProps, isEngine } from './Types';
import { UndoMistakeReplaceForm } from './UndoMistakeReplaceFrom';
import { WarrantyReplaceFrom } from './WarrantyReplaceFrom';

const undoMistakeReplaceAccordionOnlyTypeList: ProductIndividualType[] = [
	'ElectricMotor',
	'Generator',
	'ReverseGear',
	'SteeringActuator',
	'TractionBattery',
];
const repowerReplaceAccordionTypeList: ProductIndividualType[] = [
	'Drive',
	'Engine',
	'Outboard',
	'Shield',
];

interface ReplaceIndividualAccordionResolverProps
	extends IndividualReplaceProps,
		ActionDialogChildProps {}

export const SwapReplaceAccordionItem = ({
	productCategoryTransKey,
	...accordionChildProps
}: ReplaceIndividualAccordionResolverProps & {
	productCategoryTransKey: string;
}) => {
	const { t } = useTranslation(['productType', 'unitOverview']);

	return size(accordionChildProps.matchingIndividualsToSwap) > 0 ? (
		<AccordionItem
			accordionName="swap-individual"
			header={t('unitOverview:swap-individual-header', {
				productCategory: productCategoryTransKey,
			})}>
			<SwapReplaceForm
				key={SwapReplaceForm.name}
				{...accordionChildProps}
			/>
		</AccordionItem>
	) : (
		<></>
	);
};

export const ReplaceIndividualAccordionResolver = ({
	chassisId,
	matchingIndividualsToSwap,
	productCategory,
	serialNumber,
	unitId,
	...props
}: ReplaceIndividualAccordionResolverProps): JSX.Element => {
	const { t } = useTranslation(['productType', 'unitOverview']);

	const accordionProps: IndividualReplaceProps = {
		unitId,
		serialNumber,
		matchingIndividualsToSwap,
		chassisId,
		productCategory,
	};

	const productCategoryTransKey = t(
		`productType:${productCategory}` as ParseKeys<'productType'>
	);

	const productCategoryType = productCategory;

	return (
		<AccordionContainer>
			{repowerReplaceAccordionTypeList.includes(productCategoryType) && (
				<>
					<AccordionItem
						accordionName="replace-repower"
						header={
							isEngine(productCategory)
								? t(
										'unitOverview:replace-repower-engine-header'
									)
								: t(
										'unitOverview:replace-repower-individual-header',
										{
											productCategory:
												productCategoryTransKey,
											chassisId,
										}
									)
						}>
						<RepowerReplaceFrom {...accordionProps} {...props} />
					</AccordionItem>
					<AccordionItem
						accordionName="replace-warranty"
						header={
							isEngine(productCategory)
								? t(
										'unitOverview:replace-warranty-engine-header'
									)
								: t(
										'unitOverview:replace-warranty-individual-header',
										{
											productCategory:
												productCategoryTransKey,
											chassisId,
										}
									)
						}>
						<WarrantyReplaceFrom {...accordionProps} {...props} />
					</AccordionItem>
					<AccordionItem
						accordionName="replace-mistake"
						header={
							isEngine(productCategory)
								? t(
										'unitOverview:replace-mistake-engine-header'
									)
								: t(
										'unitOverview:replace-mistake-individual-header',
										{
											productCategory:
												productCategoryTransKey,
											chassisId,
										}
									)
						}>
						<UndoMistakeReplaceForm
							{...accordionProps}
							{...props}
						/>
					</AccordionItem>
					{isEngine(productCategoryType) && (
						<AccordionItem
							accordionName="replace-longblock"
							header={t(
								'unitOverview:replace-longblock-product-individual-header'
							)}>
							<LongblockReplaceForm
								{...accordionProps}
								{...props}
							/>
						</AccordionItem>
					)}
					{(productCategoryType === 'Drive' ||
						productCategoryType === 'Shield') && (
						<SwapReplaceAccordionItem
							productCategoryTransKey={productCategoryTransKey}
							{...accordionProps}
							{...props}
						/>
					)}
				</>
			)}
			{undoMistakeReplaceAccordionOnlyTypeList.includes(
				productCategoryType
			) && (
				<>
					<AccordionItem
						accordionName="replace-mistake"
						header={
							isEngine(productCategory)
								? t(
										'unitOverview:replace-mistake-engine-header'
									)
								: t(
										'unitOverview:replace-mistake-individual-header',
										{
											productCategory:
												productCategoryTransKey,
											chassisId,
										}
									)
						}>
						<UndoMistakeReplaceForm
							{...accordionProps}
							{...props}
						/>
					</AccordionItem>

					<SwapReplaceAccordionItem
						productCategoryTransKey={productCategoryTransKey}
						{...accordionProps}
						{...props}
					/>
				</>
			)}
		</AccordionContainer>
	);
};
