import { createAsyncThunk } from '@reduxjs/toolkit/react';
import { AxiosResponse } from 'axios';

import { UnitRegistrationModelGR, productCenterApi } from 'api';
import { createResetStateThunk } from 'library/redux/fileUpload/Thunks';
import {
	ProductIndividualSearchNoResults,
	mapFromUnitRegistrationModelGRToProductIndividualSearchResult,
} from 'modules/Shared/State/unit';

const validateGuidedRegistrationSearchIndividualResponse = (
	requestedSerialNumber: string,
	{
		data: { infoSource, responseType },
		status,
	}: AxiosResponse<UnitRegistrationModelGR, any>
): ProductIndividualSearchNoResults | null => {
	if (status === 204) {
		return {
			errorMessage: 'unitCreation:api-error-product-not-found',
			serialNumber: null,
		};
	}

	if (
		responseType === 'InvalidIndividualStatus' &&
		(infoSource === 'PC' || !infoSource)
	) {
		return {
			errorMessage: 'unitCreation:api-error-product-status-invalid',
			serialNumber: requestedSerialNumber,
		};
	} else if (
		responseType === 'InvalidConfiguration' ||
		responseType === 'TemporaryDriveline'
	) {
		return {
			errorMessage:
				'unitCreation:api-error-product-invalid-configuration',
			serialNumber: null,
		};
	}

	return null;
};

export const searchIndividual = createAsyncThunk(
	'guidedRegistration/searchIndividual',
	async (serialNumber: string, { rejectWithValue }) => {
		try {
			const response = await productCenterApi.searchIndividual({
				serialNumber,
			});
			const { data } = response;

			// TODO: a happy path only - add actual blueprint validation in a further ticket
			const validationError =
				validateGuidedRegistrationSearchIndividualResponse(
					serialNumber,
					response
				);

			if (validationError) {
				return rejectWithValue(validationError);
			}

			// TODO: remove after fully switch to GR #874908
			const manualResult =
				mapFromUnitRegistrationModelGRToProductIndividualSearchResult(
					data,
					serialNumber
				);

			return { ...manualResult, tree: data.tree };
		} catch (err) {
			return rejectWithValue({ errorMessage: 'common:error-unexpected' });
		}
	}
);

export const resetGuidedRegistrationState = createResetStateThunk(
	'guidedRegistration/resetState',
	(s) => s.guidedRegistrationFiles
);
