import { ButtonProps, CircularProgress } from '@mui/material';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DrivelineSearchResult, useProductCenterApi } from 'api';
import Service from 'assets/volvoIcons/Misc/Service';
import { getLinkCreateNewServiceProtocol } from 'library/components/link';
import { useDialogState } from 'library/hooks';

import { GenerateProtocolWarningDialog } from './GenerateProtocolWarningDialog';

interface ServiceButtonProps
	extends Pick<DrivelineSearchResult, 'chassisId' | 'id'> {
	ButtonElement: React.ElementType;
	buttonText?: string;
	serviceScheduledDate?: Date | null;
	buttonProps?: Omit<
		ButtonProps,
		'disabled' | 'onClick' | 'endIcon' | 'startIcon'
	>;
	openInNewWindow?: boolean;
}

export const ServiceButton = ({
	id,
	chassisId,
	serviceScheduledDate,
	ButtonElement,
	buttonText,
	buttonProps,
	openInNewWindow,
}: ServiceButtonProps): JSX.Element => {
	const { open, handleOpen, handleClose } = useDialogState();
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const navigate = useNavigate();

	const serviceProtocolId = useRef(0);
	const { api } = useProductCenterApi();

	const linkTarget = openInNewWindow ? '_blank' : '_self';

	const handleServiceButtonClicked = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.stopPropagation();

		if (chassisId == null) {
			return;
		}

		event.currentTarget.disabled = true;
		setButtonDisabled(true);

		(async () => {
			try {
				const response = await api.getLastInitiatedProtocolId({
					chassisId,
				});
				if (response.status == 200 && response.data > 0) {
					serviceProtocolId.current = response.data;
					handleOpen();
				} else {
					const link = getLinkCreateNewServiceProtocol(
						id,
						chassisId,
						serviceScheduledDate,
						openInNewWindow
					);

					openInNewWindow
						? window.open(link, linkTarget)
						: navigate(link);
				}
			} finally {
				setButtonDisabled(false);
			}
		})();
	};

	return (
		<>
			<ButtonElement
				{...buttonProps}
				disabled={buttonDisabled}
				onClick={handleServiceButtonClicked}>
				{buttonDisabled ? (
					<CircularProgress size="1.5rem" />
				) : (
					<Service />
				)}
				{buttonText ? '\u00A0' + buttonText : ''}
			</ButtonElement>
			{open && (
				<GenerateProtocolWarningDialog
					drivelineId={id}
					serviceProtocolId={serviceProtocolId.current}
					serviceScheduledDate={serviceScheduledDate}
					chassisId={chassisId as string}
					onClose={handleClose}
					linkTarget={linkTarget}
				/>
			)}
		</>
	);
};
