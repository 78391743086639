import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { useTranslation } from 'react-i18next';

import { PageContent } from 'library/components/controls';
import { reactPlugin } from 'main/azureInsights/config';
import TitleWrapper from 'main/layout/TitleWrapper';
import { SearchProductForm } from 'modules/SearchProduct/Components/SearchProductForm';

const SearchProductPage = (): JSX.Element => {
	const { t } = useTranslation(['searchProduct']);

	return (
		<>
			<TitleWrapper title={t('search-product-header')} />
			<PageContent>
				<SearchProductForm />
			</PageContent>
		</>
	);
};

export default withAITracking(
	reactPlugin,
	SearchProductPage,
	'Search product page',
	'application-insight-container'
);
