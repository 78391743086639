import { createSelector } from '@reduxjs/toolkit';

import { DrivelineDto } from 'api/responses/models/UnitCreation/ProductIndividualSearchResultDto';
import { ProductIndividualSearchResult } from 'domain/productIndividual';
import { hasDrivelinePositions } from 'domain/unit';
import { RootState } from 'store';

import { installationListSelectors, searchItemsListSelectors } from './Entity';
import { ApiStatus, Installation, MainSegment, UnitMetadata } from './Types';

export const selectActiveStep = ({ unit: { activeStep } }: RootState): number =>
	activeStep;

export const selectApiStatus = ({ unit: { status } }: RootState): ApiStatus =>
	status;

export const selectIsBlueprint = ({ unit: { isBlueprint } }: RootState) =>
	isBlueprint;

export const selectUnitMetadata = ({
	unit: { unitMetadata },
}: RootState): UnitMetadata => unitMetadata;

export const selectMainSegment = ({
	unit: {
		unitMetadata: { mainSegment },
	},
}: RootState): MainSegment | null => mainSegment;

export const selectAllSearchItems = ({
	unit,
}: RootState): ProductIndividualSearchResult[] =>
	searchItemsListSelectors.selectAll(unit);

export const selectAllInstallations = ({ unit }: RootState): Installation[] =>
	installationListSelectors.selectAll(unit);

export const selectSearchItemsWithConfiguration = ({
	unit: { searchItemWithConfigurationDetected },
}: RootState): ProductIndividualSearchResult | null =>
	searchItemWithConfigurationDetected;

export const selectShouldLoadConfigurationForItem = ({
	unit: { shouldLoadConfigurationForItem },
}: RootState): boolean | null => shouldLoadConfigurationForItem;

export const selectMechanicalPropulsionInstallationsTotal = createSelector(
	selectAllInstallations,
	(installations) =>
		installations
			.filter(({ detailedSegmentKey, installationPurposeKey }) =>
				hasDrivelinePositions(
					detailedSegmentKey,
					installationPurposeKey
				)
			)
			.reduce((prev, { drivelines: { length } }) => prev + length, 0)
);

export const selectUnitConfigurationType = ({ unit: { type } }: RootState) =>
	type;

export const selectAllProductIndividuals = createSelector(
	selectAllInstallations,
	(installations) =>
		installations.flatMap(({ drivelines }) =>
			drivelines.flatMap(({ productIndividuals }) => productIndividuals)
		)
);

export const selectInstallationById =
	(id: string) =>
	({ unit }: RootState): Installation | undefined =>
		installationListSelectors.selectById(unit, id);

export const selectDrivelineById =
	(installationId: string, drivelineId: string) =>
	({ unit }: RootState): DrivelineDto | undefined =>
		installationListSelectors
			.selectById(unit, installationId)
			?.drivelines.find(
				(driveline) => driveline.chassisId === drivelineId
			);
