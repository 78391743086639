import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Box, Theme, useMediaQuery, useTheme } from '@mui/material';
import { GridColDef, GridRowIdGetter } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useProductCenterApi } from 'api';
import { unitKitsQuery } from 'api/queries/SoftwareKitQueries';
import { KitDto } from 'api/responses/models/SoftwareKit/KitDto';
import { ParseKeys } from 'i18next';
import {
	DataGridToolbar,
	DisplayAlignment,
	GridOrCardToggleButton,
	LoadingDataWrapper,
	PageContentTab,
	SectionTitle,
	StyledBoxCardContainer,
} from 'library/components/controls';
import { LocalizedDataGrid } from 'library/components/controls/datagrid/LocalizedDataGrid';
import { InfinityScroll } from 'library/components/infinityScroll';
import { getLocalizedDateFormat } from 'library/formatters';
import { parseId } from 'library/utils/helpers/Unit';
import Variables from 'library/utils/themes/Variables';
import { reactPlugin } from 'main/azureInsights/config';
import {
	KitCard,
	renderDocuments,
	renderPrice,
} from 'modules/UnitOverview/Components/KitCard';

const KitsSectionPage = (): JSX.Element => {
	const { t } = useTranslation(['unitOverview', 'unitCreation']);
	const theme = useTheme<Variables>();
	const { unitId } = useParams();
	const cardOnlyMode = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm')
	);
	const [alignment, setAlignment] = useState<DisplayAlignment>('grid');

	const unitIdParsed = parseId(unitId);
	const renderAlignment = cardOnlyMode ? 'card' : alignment;

	const { api } = useProductCenterApi();
	const { data, isLoading } = useQuery({
		...unitKitsQuery(api, unitIdParsed),
	});

	const columns = useMemo(
		(): GridColDef<KitDto>[] => [
			{
				field: 'unitName',
				headerName: t('unitOverview:unit-name-header'),
				flex: 4,
				minWidth: 80,
			},
			{
				field: 'installationType',
				headerName: t('unitOverview:installation-type-header'),
				flex: 4,
				minWidth: 80,
				valueGetter: (_value, { installationType }) =>
					t(
						`unitCreation:InstallationPurposeValue_${installationType}` as ParseKeys<
							['unitOverview', 'unitCreation']
						>
					),
			},
			{
				field: 'driveLine',
				headerName: t('unitOverview:driveline-header'),
				flex: 4,
				minWidth: 80,
			},
			{
				field: 'kitNumber',
				headerName: t('unitOverview:kit-number-header'),
				flex: 4,
				minWidth: 80,
			},
			{
				field: 'description',
				headerName: t('unitOverview:description-header'),
				flex: 4,
				minWidth: 120,
			},
			{
				field: 'function',
				headerName: t('unitOverview:function-header'),
				flex: 4,
				minWidth: 120,
			},
			{
				field: 'documents',
				headerName: t('unitOverview:documents-header'),
				flex: 4,
				sortable: false,
				filterable: false,
				minWidth: 120,
				renderCell: (params) => renderDocuments(params.row.documents),
				display: 'flex',
			},
			{
				field: 'price',
				headerName: t('unitOverview:suggested-retail-header'),
				flex: 4,
				minWidth: 80,
				valueGetter: (_value, { currency, price }) =>
					renderPrice(t, price, currency),
			},
			{
				field: 'dateOf',
				headerName: t('unitOverview:date-header'),
				flex: 4,
				type: 'date',
				minWidth: 120,
				valueFormatter: (value) => getLocalizedDateFormat(value),
			},
			{
				field: 'status',
				headerName: t('unitOverview:status-header'),
				flex: 4,
				minWidth: 100,
			},
			{
				field: 'orderNumber',
				headerName: t('unitOverview:order-header'),
				flex: 4,
				minWidth: 120,
			},
			{
				field: 'orderBy',
				headerName: t('unitOverview:order-by-header'),
				flex: 4,
				minWidth: 120,
			},
			{
				field: 'invoiceTo',
				headerName: t('unitOverview:invoice-to-header'),
				flex: 4,
				minWidth: 120,
			},
			{
				field: 'performedBy',
				headerName: t('unitOverview:performed-by-header'),
				flex: 4,
				minWidth: 120,
			},
		],
		[t]
	);

	const getGridRowId: GridRowIdGetter<KitDto> = useCallback(
		({ driveLine, kitNumber }) => driveLine + kitNumber,
		[]
	);

	return (
		<PageContentTab>
			<SectionTitle title={t('unitOverview:kits-title')} />
			<LoadingDataWrapper<KitDto[]>
				loading={isLoading}
				data={data}
				render={(readonlyData) => (
					<>
						<Box mb={1}>
							<GridOrCardToggleButton
								sx={(theme) => ({
									display: cardOnlyMode ? 'none' : 'block',
									minWidth: theme.spacing(10),
								})}
								value={alignment}
								onChange={setAlignment}
							/>
						</Box>
						{renderAlignment === 'card' ? (
							<StyledBoxCardContainer>
								<InfinityScroll
									data={readonlyData}
									Item={KitCard}
								/>
							</StyledBoxCardContainer>
						) : (
							<Box height={theme.custom.dataGridHeight}>
								<LocalizedDataGrid
									columns={columns}
									getRowId={getGridRowId}
									rows={readonlyData}
									slots={{ toolbar: DataGridToolbar }}
								/>
							</Box>
						)}
					</>
				)}
			/>
		</PageContentTab>
	);
};

export default withAITracking(
	reactPlugin,
	KitsSectionPage,
	'UnitOverviewKitsSectionPage',
	'application-insight-container'
);
