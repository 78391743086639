import { DrivelineViewDto, ProductIndividualItemPurpose } from 'api';
import { ProductIndividualType } from 'domain/unit/enums';

const isEngine = (category: ProductIndividualType) =>
	category === 'Engine' || category === 'Outboard';

const isDrive = (category: ProductIndividualType) => category === 'Drive';

// todo - connected with removing driveline in registration, if it is not main
export const isMainType = (category: ProductIndividualType): boolean =>
	isEngine(category) || category === 'ControlSystem';

export const canEditHours = (category: ProductIndividualType): boolean =>
	isEngine(category) || isDrive(category);

// todo - should IPS 40 be allowed to edit modules
export const canEditModules = (category: ProductIndividualType): boolean =>
	isEngine(category);

export const isIPS40 = (productName: string | null) =>
	productName?.startsWith('PD5') ?? false;

export const getMainServiceableProductFromDriveline = (
	driveline: DrivelineViewDto
): ProductIndividualItemPurpose | null => {
	const productsInDriveline = driveline.productIndividualSubSystems.flatMap(
		(p) => p.productIndividuals
	);

	const engine = productsInDriveline.find((p) => isEngine(p.productCategory));

	if (engine) {
		return engine;
	}

	const drive = productsInDriveline.find((p) => isDrive(p.productCategory));

	return drive ? drive : null;
};
