import { Close } from '@mui/icons-material';
import {
	Alert,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Stack,
	Tab,
	Tabs,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PdfIcon } from 'assets/images/documents';
import { ControlledCommentBox } from 'library/components';
import { BlockedDoubleClickLoadingButton } from 'library/components/controls/buttons/BlockedDoubleClickLoadingButton';
import { ControlledSwitch } from 'library/components/controls/form/ControlledSwitch';
import { isVppn } from 'main/auth/provider';

import { usePdfFormSubmit } from './PdfFormSubmit';
import { mapFormToPdfRequest } from './Types';

type TabType = 'dealer' | 'customer';

export const PrintProtocolDialog = ({
	protocolId,
	isOffline,
	isOpen,
	onClose,
}: {
	protocolId: number;
	isOffline: boolean;
	isOpen: boolean;
	onClose: () => void;
}) => {
	const { t } = useTranslation(['commonButton', 'serviceProtocol', 'common']);
	const [tabValue, setTabValue] = useState<TabType>('dealer');
	const isDealer = tabValue === 'dealer';

	const onTabChange = (_: React.SyntheticEvent, newValue: TabType) => {
		setTabValue(newValue);
	};

	const { onSubmit, control, isPending } = usePdfFormSubmit({
		protocolId,
		mapFormToRequest: (pId, form) =>
			mapFormToPdfRequest(pId, form, isVppn(), isOffline, isDealer),
	});

	return (
		<Dialog open={isOpen} sx={{ whiteSpace: 'pre-line' }}>
			<DialogTitle sx={{ marginRight: 2 }}>
				{t('serviceProtocol:protocolPrintout-info-title')}
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={(theme) => ({
						position: 'absolute',
						right: theme.spacing(1),
						top: theme.spacing(1.5),
						color: theme.palette.grey[500],
					})}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Alert severity={'info'}>
					{t('serviceProtocol:protocolPrintout-info-content')}
				</Alert>

				<form noValidate onSubmit={onSubmit} id="printProtocolForm">
					<Typography variant="body1" sx={{ mt: 2, p: 1 }}>
						{t(
							'serviceProtocol:protocolPrintout-additionalInformation'
						)}
					</Typography>
					<ControlledCommentBox
						id="additionalText"
						name="additionalText"
						sx={{ mb: 2, width: '100%' }}
						label={t(
							'serviceProtocol:protocolPrintout-additionalInformation-alt'
						)}
						control={control}
						size="small"
					/>

					<Tabs
						sx={{ width: '100%' }}
						value={tabValue}
						onChange={onTabChange}>
						<Tab
							value={'dealer'}
							label={t(
								'serviceProtocol:protocolPrintout-forDealder'
							)}
						/>
						<Tab
							value={'customer'}
							label={t(
								'serviceProtocol:protocolPrintout-forCustomer'
							)}
						/>
					</Tabs>

					<Typography variant="body1" sx={{ my: 2 }}>
						{t('serviceProtocol:protocolPrintout-switchLabel')}
					</Typography>
					<Stack direction="column">
						{isDealer && (
							<ControlledSwitch
								control={control}
								name="printQr"
								label={t('serviceProtocol:protocolPrintout-qr')}
								color="secondary"
							/>
						)}
						{!isOffline && (
							<>
								<ControlledSwitch
									control={control}
									name="printEstimatedHours"
									label={t(
										'serviceProtocol:protocolPrintout-estimatedHours'
									)}
									color="secondary"
								/>
								<ControlledSwitch
									control={control}
									name="printUsedHours"
									label={t(
										'serviceProtocol:protocolPrintout-usedHours'
									)}
									color="secondary"
								/>
							</>
						)}
						<ControlledSwitch
							control={control}
							name="printConfiguration"
							label={t(
								'serviceProtocol:protocolPrintout-configuration'
							)}
							color="secondary"
						/>
						<ControlledSwitch
							control={control}
							name="printEndCustomer"
							label={t(
								'serviceProtocol:protocolPrintout-endCustomer'
							)}
							color="secondary"
						/>
						<ControlledSwitch
							control={control}
							name="printCampaigns"
							label={t(
								'serviceProtocol:protocolPrintout-campaigns'
							)}
							color="secondary"
						/>
						<ControlledSwitch
							control={control}
							name="printCoverage"
							label={t(
								'serviceProtocol:protocolPrintout-coverages'
							)}
							color="secondary"
						/>
						{!isOffline && (
							<>
								<ControlledSwitch
									control={control}
									name="printProposals"
									label={t(
										'serviceProtocol:protocolPrintout-proposals'
									)}
									color="secondary"
								/>
								<ControlledSwitch
									control={control}
									name="printServiceActivitiesPhotos"
									label={t(
										'serviceProtocol:protocolPrintout-activitiesPhotos'
									)}
									color="secondary"
								/>
							</>
						)}
						<ControlledSwitch
							control={control}
							name="printAdditionalRepairs"
							label={
								isOffline
									? t(
											'serviceProtocol:protocolPrintout-additionalRepairsOffline'
										)
									: t(
											'serviceProtocol:protocolPrintout-additionalRepairs'
										)
							}
							color="secondary"
						/>
						<ControlledSwitch
							control={control}
							name="printAdditionalPhotos"
							label={t(
								'serviceProtocol:protocolPrintout-additionalRepairs-photos'
							)}
							color="secondary"
						/>
						<ControlledSwitch
							control={control}
							name="printSignatures"
							label={t(
								'serviceProtocol:protocolPrintout-signatures'
							)}
							color="secondary"
						/>
					</Stack>
				</form>
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'space-between' }}>
				<Button
					variant="outlined"
					color="secondary"
					disableElevation
					onClick={onClose}>
					{t('commonButton:cancel')}
				</Button>
				<BlockedDoubleClickLoadingButton
					variant="contained"
					color="secondary"
					type="submit"
					form="printProtocolForm"
					disableElevation
					loading={isPending}
					endIcon={<PdfIcon />}>
					{t('commonButton:print')}
				</BlockedDoubleClickLoadingButton>
			</DialogActions>
		</Dialog>
	);
};
