export const MAX_FILE_SIZE = 10485760;
export const MAX_FILE_SIZE_MB = MAX_FILE_SIZE / 1024 / 1024;
export const MIN_FILE_LIMIT = 1;
export const MAX_FILE_LIMIT = 10;

export const IMAGE_EXTENSIONS = /\.(jfif|bmp|gif|png|jpeg|jpg|ico|webp|avif)$/;
export const IMAGE_EXTENSIONS_STRING = IMAGE_EXTENSIONS.toString()
	.replaceAll(/[.()$\\/]/g, '')
	.replaceAll(/^|\|/g, ' .');

export const ALLOWED_FILE_EXTENSIONS = new RegExp(
	/\.(pdf|doc|docx|xls|xlsx|slk|vlr)$/.source + '|' + IMAGE_EXTENSIONS.source
);

export const ALLOWED_FILE_EXTENSIONS_STRING = ALLOWED_FILE_EXTENSIONS.toString()
	.replaceAll(/[.()$\\/]/g, '')
	.replaceAll(/^|\|/g, ', .')
	.trim();

export const isValidFileUploadSize = (file: FileValidatable) => {
	return file.size <= MAX_FILE_SIZE;
};

export const isValidFileUploadDuplicates = (
	uploadedFiles: FileValidatable[],
	currentFile: FileValidatable
) => {
	return !uploadedFiles.find(
		({ lastModified, size, type }) =>
			currentFile.lastModified === lastModified &&
			currentFile.size === size &&
			currentFile.type === type
	);
};

export interface FileValidatable {
	lastModified: number;
	size: number;
	type: string;
}
