import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getUrlServiceProtocolPDFDownload } from 'api/ApiUrls';
import { createDownloadFileFormOnClick } from 'library/utils/helpers/FileHelper';
import { useCheckConsoleDebuggingReadAccess } from 'main/auth/checkUserAccess';
import {
	AuthProviderStorage,
	AuthenticationProvider,
} from 'main/auth/provider';

import { PrintProtocolFormType, mapFormToPdfRequest } from './Types';

export const usePdfFormSubmit = ({
	protocolId,
	mapFormToRequest,
}: {
	protocolId: number;
	mapFormToRequest: (
		protocolId: number,
		form: PrintProtocolFormType
	) => ReturnType<typeof mapFormToPdfRequest>;
}) => {
	const { t } = useTranslation('common');
	const { enqueueSnackbar } = useSnackbar();
	const { checkConsoleDebuggingReadAccess } =
		useCheckConsoleDebuggingReadAccess();
	const { handleSubmit, control } = useForm<PrintProtocolFormType>({
		mode: 'onBlur',
		defaultValues: {
			printQr: true,
			printEstimatedHours: true,
			printUsedHours: true,
			printConfiguration: true,
			printEndCustomer: true,
			printCampaigns: true,
			printCoverage: true,
			printProposals: true,
			printServiceActivitiesPhotos: true,
			printAdditionalRepairs: true,
			printAdditionalPhotos: true,
			printSignatures: true,
		},
	});
	const [isPending, setIsPending] = useState(false);

	const [provider] = useState<AuthenticationProvider>(
		AuthProviderStorage.getInstance().getProvider()
	);

	const onSubmit = handleSubmit(async (data) => {
		setIsPending(true);

		try {
			const name = `Service Protocol ${protocolId} ${new Date().toISOString().substring(0, 10)}.pdf`;
			const token = await provider.getTokenAsync();
			const url = getUrlServiceProtocolPDFDownload();
			const formData = mapFormToRequest(protocolId, data);

			createDownloadFileFormOnClick(token, url, name, formData);

			setIsPending(false);
		} catch (error) {
			setIsPending(false);
			checkConsoleDebuggingReadAccess() && console.error(error);

			enqueueSnackbar(t('error-unexpected'), {
				variant: 'error',
				style: { whiteSpace: 'pre-line' },
			});
		}
	});

	return { onSubmit, control, isPending };
};
