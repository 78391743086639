import { Search } from '@mui/icons-material';
import { InputAdornment, TextField, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SearchProductTextField = styled(TextField)({
	'& .MuiInputLabel-root': {
		fontSize: 32,
		color: '#919296',
		fontWeight: 300,
	},

	'& .MuiInputLabel-shrink': {
		fontSize: 24,
	},

	'& .MuiInputBase-input': {
		fontSize: 32,
		paddingBottom: 16,
		paddingTop: 16,
	},
});

export const SearchProductForm = () => {
	const { t } = useTranslation(['searchProduct']);

	return (
		<SearchProductTextField
			label={t('search-input-helper-text')}
			variant="standard"
			fullWidth
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Search />
					</InputAdornment>
				),
			}}
		/>
	);
};
