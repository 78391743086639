import { ComponentType } from 'react';

import {
	ExcelIcon,
	ImgIcon,
	PdfIcon,
	PptIcon,
	TxtIcon,
	WordIcon,
	ZipIcon,
} from 'assets/images/documents';
import { FilteredReason } from 'library/hooks/UseUploadController/Types';

import {
	ALLOWED_FILE_EXTENSIONS,
	MIN_FILE_LIMIT,
	isValidFileUploadDuplicates,
	isValidFileUploadSize,
} from './fileUploadValidation';

export const fileExtensionToFileTypeMap: { [extensions: string]: string } = {
	pdf: 'application/pdf',
	zip: 'application/zip',
	'7z': 'application/x-7z-compressed',
	rar: 'application/vnd.rar',
	txt: 'text/plain',
	doc: 'application/msword',
	docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	ppt: 'application/vnd.ms-powerpoint',
	pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	xls: 'application/vnd.ms-excel',
	xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	jpg: 'image/jpeg',
	jpeg: 'image/jpeg',
	bmp: 'image/bmp',
	webp: 'image/webp',
	png: 'image/png',
	svg: 'image/svg+xml',
};

export const fileTypeToIconMap: { [mimeType: string]: ComponentType } = {
	'application/pdf': PdfIcon,
	'application/zip': ZipIcon,
	'application/x-zip-compressed': ZipIcon,
	'application/x-7z-compressed': ZipIcon,
	'application/vnd.rar': ZipIcon,
	'application/msword': WordIcon,
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
		WordIcon,
	'text/plain': TxtIcon,
	'application/vnd.ms-powerpoint': PptIcon,
	'application/vnd.openxmlformats-officedocument.presentationml.presentation':
		PptIcon,
	'image/jpeg': ImgIcon,
	'image/bmp': ImgIcon,
	'image/webp': ImgIcon,
	'image/png': ImgIcon,
	'image/svg+xml': ImgIcon,
	'application/vnd.ms-excel': ExcelIcon,
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
		ExcelIcon,
};

export function readFile(file: File): Promise<string | ArrayBuffer | null> {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.addEventListener('load', () => resolve(reader.result), false);
		reader.readAsDataURL(file);
	});
}

export function bytesToSize(bytes: number, decimals = 2) {
	if (bytes === 0) return '0 KB';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const getFileType = (filename: string) =>
	fileExtensionToFileTypeMap[getFileExtension(filename)];

export const getFileExtension = (filename: string) =>
	filename.substring(filename.lastIndexOf('.') + 1, filename.length) ||
	filename;

const checkExtension = (
	invalidFiles: File[],
	file: File,
	extensions: RegExp
) => {
	if (!file.name.toLowerCase().match(extensions)) {
		invalidFiles.push(file);
	}
};

const checkSize = (invalidFiles: File[], file: File) => {
	if (!isValidFileUploadSize(file)) {
		invalidFiles.push(file);
	}
};

const checkDuplicate = (
	invalidFiles: File[],
	file: File,
	uploadedFiles: File[]
) => {
	if (!isValidFileUploadDuplicates(uploadedFiles, file)) {
		invalidFiles.push(file);
	}
};

const setValidation = (
	reason: FilteredReason,
	results: Map<FilteredReason, File[]>,
	array: File[]
) => {
	if (array.length > 0) {
		results.set(reason, array);
	}
};

export const validateFiles = (
	newFiles: File[],
	uploadedFiles: File[] = [],
	extensions = ALLOWED_FILE_EXTENSIONS,
	uploadFileLimit = MIN_FILE_LIMIT
): Map<FilteredReason, File[]> | undefined => {
	const results: Map<FilteredReason, File[]> = new Map();

	if (newFiles.length + uploadedFiles.length > uploadFileLimit) {
		results.set(
			'TooMany',
			newFiles.slice(Math.abs(uploadFileLimit - uploadedFiles.length))
		);

		return results;
	} else {
		const wrongExtensionFiles: File[] = [];
		const wrongSizeFiles: File[] = [];
		const alreadyExistFiles: File[] = [];

		for (let i = 0; i < newFiles.length; i++) {
			const file = newFiles[i];
			checkExtension(wrongExtensionFiles, file, extensions);
			checkSize(wrongSizeFiles, file);
			checkDuplicate(alreadyExistFiles, file, uploadedFiles);
		}

		setValidation('WrongExtension', results, wrongExtensionFiles);
		setValidation('TooBig', results, wrongSizeFiles);
		setValidation('AlreadyExists', results, alreadyExistFiles);

		return results.size > 0 ? results : undefined;
	}
};
