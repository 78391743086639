import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { AreaName } from 'domain/constants';
import { OperationType } from 'library/models/enums/OperationType';
import { createArea } from 'library/utils/helpers/AreaHelper';
import Authorize from 'main/auth/Authorize';

const TypographyPage = lazy(
	() => import('pages/developers/DevelopersTypographyPage')
);
const ComponentsPage = lazy(
	() => import('pages/developers/DevelopersComponentsPage')
);
const DeploymentAlertPage = lazy(
	() => import('pages/developers/DevelopersDeploymentAlertPage')
);

export const getDevelopersRoutes = (): JSX.Element[] => {
	return [
		<Route
			key={'typography'}
			path={'typography'}
			element={
				<Authorize
					area={createArea(
						AreaName.DevelopersTestPage,
						OperationType.Read
					)}>
					<TypographyPage />
				</Authorize>
			}
		/>,
		<Route
			key={'components'}
			path={'components'}
			element={
				<Authorize
					area={createArea(
						AreaName.DevelopersTestPage,
						OperationType.Read
					)}>
					<ComponentsPage />
				</Authorize>
			}
		/>,
		<Route
			key={'managedeploymentalert'}
			path={'managedeploymentalert'}
			element={
				<Authorize
					area={createArea(
						AreaName.ManageDeploymentAlert,
						OperationType.Read
					)}>
					<DeploymentAlertPage />
				</Authorize>
			}
		/>,
	];
};
