/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTheme } from '@mui/material';
import { DataGridProProps, GridValidRowModel } from '@mui/x-data-grid-pro';
import { ReactNode } from 'react';

import Variables from 'library/utils/themes/Variables';

import { LoadingDataWrapper, LoadingWrapperProps } from '../loaders';
import { LocalizedDataGrid } from './LocalizedDataGrid';

export type VolvoDataGridProps<R extends GridValidRowModel = any> = Omit<
	DataGridProProps<R> & React.RefAttributes<HTMLDivElement>,
	'rows' | 'isLoading'
> & {
	rows: DataGridProProps<R>['rows'] | null | undefined;
	isLoading: LoadingDataWrapper<R>['loading'];
	isInnerGrid?: boolean;
	loadingWrapperProps?: Omit<LoadingWrapperProps, 'loading'>;
	noDataComponent?: ReactNode;
	isOrderInfoTable?: boolean;
};

export const VolvoDataGrid = <R extends GridValidRowModel = any>({
	rows,
	isLoading,
	isInnerGrid = false,
	loadingWrapperProps,
	noDataComponent,
	isOrderInfoTable = false,
	sx = [],
	...props
}: VolvoDataGridProps<R>) => {
	const theme = useTheme<Variables>();

	const rowNumbers = rows?.length ?? 0;

	//Sonar qube need to have it in 2 separeta conditions
	// test structure cannot allow to be it outside function

	const isInnerStyleChoice = isInnerGrid ? innerDataGridStyle : null;

	const finalTableStyle = isOrderInfoTable
		? innerOrderInfoGridStyle
		: isInnerStyleChoice;

	return (
		<LoadingDataWrapper
			data={rows}
			loading={isLoading}
			noDataComponent={noDataComponent}
			slotProps={{
				dataGridWrapper: {
					height:
						(!isInnerGrid && !isOrderInfoTable) ||
						(isOrderInfoTable && rowNumbers > 10)
							? theme.custom.dataGridHeight
							: 'unset',
					maxHeight: isInnerGrid ? '450px' : 'unset',
				},
				noDataWrapper: {
					sx: isInnerGrid ? innerDatGridNoDataBoxStyle : null,
				},
				loadingWrapper: loadingWrapperProps,
			}}
			render={(data) => (
				<LocalizedDataGrid
					autoHeight={isInnerGrid}
					rows={data}
					{...props}
					sx={[finalTableStyle, ...(Array.isArray(sx) ? sx : [sx])]}
				/>
			)}
		/>
	);
};

const innerDataGridStyle = {
	// any padding and margin horizontal breaks scrolling
	my: 0,
	py: 0,
	mx: 4,
	'& .MuiDataGrid-columnHeaders': {
		borderTopColor: 'transparent',
	},
};

const innerOrderInfoGridStyle = {
	// any padding and margin horizontal breaks scrolling
	my: 0,
	py: 0,
	mx: 4,
	'& .MuiDataGrid-filler': {
		height: '0px !important',
		border: '0px solid !important',
	},
};

const innerDatGridNoDataBoxStyle = {
	height: 100,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};
