import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { DimensionsType } from 'api/responses/models/Unit/UnitMeasurement';
import {
	ApiStatus,
	UnitConfigurationType,
} from 'modules/Shared/State/unit/Types';

import { searchIndividual } from './Thunks';
import { GuidedRegistrationState, UnitDataFormTypes } from './Types';

export const DEFAULT_DIMENSIONS_TYPE: DimensionsType = 'Estimated';

export const initialState: GuidedRegistrationState = {
	unitData: {
		hullIdStandard: null,
		dimensionsType: DEFAULT_DIMENSIONS_TYPE,
		length: '',
		lengthUnit: '',
		width: '',
		widthUnit: '',
		displacement: '',
		displacementUnit: '',
		lineOfBusiness: '',
		unitPurpose: '',
	},
	activeStep: 0,
	unitMetadata: {
		lineOfBusiness: undefined,
		lineOfBusinessDescription: undefined,
		unitPurpose: undefined,
		unitPurposeDescription: undefined,
		mainSegment: null,
	},
	status: ApiStatus.Idle,
	type: UnitConfigurationType.CREATION,
	infoSource: '',
};

export const guidedRegistrationSlice = createSlice({
	name: 'guidedRegistration',
	initialState,
	reducers: {
		nextStep: (state) => {
			state.activeStep += 1;
		},
		previousStep: (state) => {
			if (state.activeStep === 0) {
				return;
			}

			state.activeStep -= 1;
		},
		updateFormData: (
			state,
			{ payload }: PayloadAction<UnitDataFormTypes>
		) => {
			state.unitData = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(searchIndividual.pending, (state) => {
				state.status = ApiStatus.Pending;
			})
			.addCase(
				searchIndividual.fulfilled,
				(state, { payload: { tree } }) => {
					state.status = ApiStatus.Idle;
					state.tree = tree;
				}
			);
	},
});

export const {
	actions: { nextStep, previousStep, updateFormData },
} = guidedRegistrationSlice;

export default guidedRegistrationSlice.reducer;
